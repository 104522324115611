$(function () {
	/*$('.core-nav a').click(function (event) {
		event.preventDefault();
	});*/

	$('.nav-trigger').click(function (event) {
		$('body').toggleClass('mobile-nav-open');
		$('.nav-panels').removeClass('open');
		$('.nav-panel').removeClass('open');
		event.preventDefault();
	});

	$('.mobile-nav-heading').click(function (event) {
		if ($(event.currentTarget).attr('href') == '#') {
			let panelId = $(event.currentTarget).attr('dropdown');
			$('#' + panelId).toggleClass('open');
			$(this).toggleClass('open');
			event.preventDefault();
		}
	});

	/* $('.mobile-nav-links a').click(function (event) {
		if ($(event.currentTarget).attr('href') == '#') {
			let panelId = $(event.currentTarget).attr('dropdown');

			$('.nav-panels').addClass('open');
			$('.nav-panel').removeClass('open');
			$('#' + panelId).addClass('open');

			event.preventDefault();
		}
	}); */

	/*$('.nav-panel__mobile-header .back-button').click(function (event) {
		$('.nav-panels').removeClass('open');
		$('.nav-panel').removeClass('open');
		event.preventDefault();
	});*/

	$('.nav-panel .nav-close').click(function (event) {
		$('.nav-panels').removeClass('open');
		$('.nav-panel').removeClass('open');
		$('.core-nav a').removeClass('active');
		event.preventDefault();
	});

	/* Nav indicator
	function activeNavIndicator(firstLoad) {
		let active = $('.core-nav .active');

		if (active.length) {
			let activeInfo = getNavItemSize(active);
			$('.nav-indicator').removeClass('nav-indicator--hidden');

			if (firstLoad) {
				$('.nav-indicator').css({
					width: activeInfo.width,
					left: activeInfo.left,
				});
				$('.nav-indicator').animate(
					{
						opacity: 1,
					},
					{ queue: false, duration: 250 }
				);
			} else {
				$('.nav-indicator').animate(
					{
						width: activeInfo.width,
						left: activeInfo.left,
						opacity: 1,
					},
					{ queue: false, duration: 250 }
				);
			}
		} else {
			$('.nav-indicator').animate(
				{
					opacity: 0,
				},
				{ queue: false, duration: 250 }
			);
			$('.nav-indicator').addClass('nav-indicator--hidden');
		}
	} */

	// In case the mouse exits the nav at the top
	$('body').mouseleave(function (element) {
		$('.core-nav a').removeClass('active');
	});

	$('.core-nav a')
		.mouseenter(function (element) {
			$('.core-nav a').removeClass('active');
			$(this).addClass('active');
			/*let activeInfo = getNavItemSize(element.currentTarget);
			if ($('.nav-indicator').hasClass('nav-indicator--hidden')) {
				$('.nav-indicator').removeClass('nav-indicator--hidden');
				$('.nav-indicator').css({
					width: activeInfo.width,
					left: activeInfo.left,
				});
				$('.nav-indicator').animate(
					{
						opacity: 1,
					},
					{ queue: false, duration: 250 }
				);
			} else {
				$('.nav-indicator').removeClass('nav-indicator--hidden');
				$('.nav-indicator').animate(
					{
						width: activeInfo.width,
						left: activeInfo.left,
						opacity: 1,
					},
					{ queue: false, duration: 250 }
				);
			}*/

			let panelId = $(element.currentTarget).attr('dropdown');

			$('.nav-panel').removeClass('open');
			$('.nav-panels').addClass('open');
			$('header').addClass('nav-open');
			$('html, body').addClass('nav-open');
			$('#' + panelId).addClass('open');
		})
		.mouseleave(function (element) {
			setTimeout(function () {
				const hovered = $('.core-nav, .nav-panel').filter(function () {
					return $(this).is(':hover');
				});

				if (hovered.length == 0) {
					$('.nav-panels').removeClass('open');
					$('.nav-panel').removeClass('open');
					$('header').removeClass('nav-open');
					//activeNavIndicator(false);
				}
			}, 250);
		});

	$('.nav-panel').mouseleave(function () {
		let windowWidth = $(document).width();
		if (windowWidth > 1250) {
			$('.nav-panels').removeClass('open');
			$('.nav-panel').removeClass('open');
			$('header').removeClass('nav-open');
			$('html, body').removeClass('nav-open');
			//activeNavIndicator(false);
		}
		$('.core-nav a').removeClass('active');
	});

	//activeNavIndicator(true);

	function getNavItemSize(element) {
		let navItem = $(element).find('span');
		let width = $(navItem).width();
		let parentPosition = $(navItem).parent().position();
		let position = $(navItem).position();
		let left = parentPosition.left + position.left;

		return {
			width: width,
			left: left,
		};
	}

	/* Dropdown not sure if we need this for main nav */
	// Dropdown
	$('.dropdown__active').click(function (event) {
		if ($(this).closest('.dropdown').hasClass('open')) {
			$('.dropdown').removeClass('open');
			$('body').removeClass('dropdown-open');
		} else {
			$('.dropdown').removeClass('open');
			$(this).closest('.dropdown').addClass('open');
			$('body').addClass('dropdown-open');
		}

		event.preventDefault();
	});

	$('.close-dropdown-popup').click(function (event) {
		closeDropdown();

		event.preventDefault();
	});

	function closeDropdown() {
		$('.dropdown__active').closest('.dropdown').removeClass('open');
		$('body').removeClass('dropdown-open');
	}

	$(document).bind('click', function (event) {
		var clicked = $(event.target);

		// Clicking off dropdown closes it
		if (!clicked.parents().hasClass('dropdown')) {
			closeDropdown();
		}
	});

	/*=========================================
	=                ON RESIZE                =
	=========================================*/
	$(window).on('resize', function () {
		//activeNavIndicator();

		// Hide navs
		$('body').removeClass('mobile-nav-open');
		$('.nav-panels').removeClass('open');
		$('.nav-panel').removeClass('open');

		closeDropdown();

		//spaceForAnnouncements();
	});

});

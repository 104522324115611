$(function() {
	$('.participants-tab').click(function (event) {
		let panelId = $(event.currentTarget).attr('href');
		$('.participants-tabs').find('.participants-tab').removeClass('open');
		$(this).addClass('open');
		$('.participants__grid').removeClass('open');
		$(panelId).addClass('open');
		event.preventDefault();
	});
});

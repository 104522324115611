/*=========================================
=                Search Pannel            =
=========================================*/
$('.search-button').on('click', function(event){
	$('#searchpanel, header').toggleClass('search-open');
	event.preventDefault;
});

$('.search-close').on('click', function(event){
	$('#searchpanel, header').removeClass('search-open');
	event.preventDefault;
});

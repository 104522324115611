/*==============================
=          GLOBAL  JS          =
==============================*/
$(function() {
	function header_height() {
		$('body').css('padding-top', $('header').outerHeight());
	}
	header_height();

	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;

	$(window).scroll(function(event){
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(window).scrollTop();
		var navbarHeight = $('header').outerHeight();
		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
			// Scroll Down
			$('header').css('top', '-' + navbarHeight + 'px');
			$('.page-content-shim').css('display','none');
		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {
				$('header').css('top', '0px');
				$('.page-content-shim').css('display','block');
			}
		}

		lastScrollTop = st;
	}

	// Tracking for closing Sidr
	var window_width = $(window).width();
	var window_height = $(window).height();

	$(window).on('load', function(){
		// Tracking for closing Sidr
		window_height = $(window).height();
		window_height = $(window).width();
	});

	/*=========================================
	=              Window Resize              =
	=========================================*/
	$(window).on('resize', function(){
		header_height();
	});
});

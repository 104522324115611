$(function() {
	// Copy search query and submit filter form instead of search form to preserve filter form state
	$('.resources-overview__search-filter .search-form').submit(function(event) {
		event.preventDefault();
		var value =  $('#resource-search-input').val();
		$('.filter-form__query-input').val(value);
		$('#filter-form').submit();
	});

	$('#filter-toggle').click(function(event) {
		$('.resources-overview__filter-form').toggle({speed: 400}).toggleClass('open');
		$('#filter-toggle').toggleClass('open');
		if ( $('.resources-overview__filter-form').hasClass('open') ) {
				$('#filter-toggle').css('bottom','-45px');
				$('#filter-toggle h6').html('HIDE FILTERS');
			}
		else {
			$('#filter-form input:checkbox').prop('checked', false);
			$('#filter-toggle h6').html('SHOW FILTERS');
			$('#filter-toggle').css('bottom','-65px');
		}
	});
});

$('.accordion__heading').on('click', function(e) {
	if(!$(this).next().is(':animated')) {
		if ($(this).parent().hasClass('open')) {
			$(this).next().slideUp(250);
			$(this).closest('.accordion__item').removeClass('open');
		}
		else {
			$(this).closest('.accordion').find('.accordion__body').slideUp(250);
			$(this).next().slideDown(250);
			$(this).closest('.accordion').find('.accordion__item').removeClass('open');
			$(this).closest('.accordion__item').addClass('open');
		}
	}

	e.preventDefault();
});
